export const Title = "Fauzan Al Marsus ";

export const tagTitle = " Visual Artist Photography & Videography";

export const textAbout =
  "Fauzan al marsus. Berawal dari hobi tentang dunia fotografi dan videografi. Yang kemudian menjadi profesi sebagai team kreatif di suatu prusahaan. Menjadikan diri lebih berkembang tentang dunia kreatif dan fotografi";

export const About_two = "Abadikan setiap momen istimewa Anda, termasuk kategori pernikahan, acara, produk, wisuda, dan lainnya.";

export const Gallerytext = "Abadikan setiap momen istimewa. Berikut adalah portofolio kami.";
